import React from 'react'
import moment from 'moment'
import Message from './Message'

function _assignPosition(pre, index, post) {
  let position = 'single'
  if (pre !== index && post === index) {
    position = 'first'
  } else if (pre === index && post === index) {
    position = 'mid'
  } else if (pre === index && post !== index) {
    position = 'last'
  }
  return position
}
function _handleMessageType(type, message, file) {
  let result
  switch (type) {
    case 'ADMM':
    case 'MESG': {
      result = message
      break
    }
    case 'FILE': {
      result = file.url
      break
    }
    default: {
      console.log(`${type}: ${message}`)
      break
    }
  }
  return result
}
function _renderEachDayMessage(days) {
  const firstDate = moment(days[0]?.created_at)
  const result = days.map((mesg, i) => {
    let convertDate = firstDate
    const prev = days[i - 1]?.from
    const next = days[i + 1]?.from
    const { from, message, type, file } = mesg
    const messageToBeRendered = _handleMessageType(type, message, file)
    const renderPosition = _assignPosition(prev, from, next)
    const diffDay = moment(mesg.created_at).diff(firstDate, 'days')
    if (i > 0 && diffDay <= 0) {
      convertDate = ''
    }
    let senderTemp = from
    if (type === 'ADMM') {
      senderTemp = 'admin'
    }
    return (
      <Message
        key={i}
        sender={senderTemp}
        message={messageToBeRendered}
        position={renderPosition}
        date={convertDate}
      />
    )
  })
  return result
}
const ChatMessages = ({ history }) => {
  const renderEachDay = history.map((item) =>
    _renderEachDayMessage(item.messages)
  )
  return renderEachDay
}

export default ChatMessages
