import { createDetailsWidget } from '@livechat/agent-app-sdk'
import { accountsSdk } from '@livechat/accounts-sdk'
import React from 'react'
import moment from 'moment'
import { getUserStatusRequest } from '../containers/Main/actions'
import clockIcon from '../img/clock.png'
import offlineIcon from '../img/status_offline.png'
import onlineIcon from '../img/status_online.png'
import unknownIcon from '../img/status_unknown.png'
import loaderIcon from '../img/loader.gif'

export const config = {
  client_id: process.env.REACT_APP_CLIENT_ID,
  server_url: '',
  account_url: process.env.REACT_APP_LIVECHAT_ENDPOINT,
}

export const useProfileChat = () => {
  const [chatProfile, setChatProfile] = React.useState(null)
  createDetailsWidget().then((widget) => {
    widget.on('customer_profile', (profile) => {
      setChatProfile(profile.chat.chat_id)
    })
  })
  return [chatProfile]
}

let intervalID = null
const _intervalManager = (flag, animate, time) => {
  if (flag) intervalID = setInterval(animate, time)
  else clearInterval(intervalID)
}
const _widgetModifying = (widget, response) => {
  let statusItem
  if (response) {
    if (response === 'loading') {
      statusItem = {
        text: 'Please wait...',
        img: `${process.env.REACT_APP_FRONTEND_URL}/${loaderIcon}`,
        lastSeen: {
          label: ``,
        },
      }
    } else {
      const now = moment()
      const lastSeen = moment(response.last_seen_at)
      const _calculateDeltaTime = (time) => {
        const diffMin = now.diff(time, 'minutes')
        const diffHour = now.diff(time, 'hours')
        const diffDay = now.diff(time, 'days')
        let differenceTime
        if (diffDay <= 0) {
          if (diffHour <= 0) {
            differenceTime = `${diffMin} mins`
          } else if (diffHour > 0 && diffMin > 0) {
            differenceTime = `${diffHour} hours ${diffMin - diffHour * 60} mins`
          } else if (diffMin - diffHour * 60 === 0) {
            differenceTime = `${diffHour} hours`
          }
        } else {
          differenceTime = `${diffDay} days`
        }
        return differenceTime
      }
      statusItem =
        response.is_online === true
          ? {
              text: 'Online',
              img: onlineIcon,
              lastSeen: {
                label: ``,
              },
            }
          : {
              text: 'Offline',
              img: offlineIcon,
              lastSeen: {
                label: `About ${_calculateDeltaTime(lastSeen)} ago`,
                iconUrl: clockIcon,
              },
            }
    }
  } else {
    statusItem = {
      text: 'Unknown',
      img: unknownIcon,
      lastSeen: {
        label: ``,
      },
    }
  }
  widget.modifySection({
    title: 'Status',
    components: [
      {
        type: 'label_value',
        data: {
          label: statusItem.text,
          iconUrl: statusItem.img,
        },
      },
      {
        type: 'label_value',
        data: {
          ...statusItem.lastSeen,
        },
      },
    ],
  })
}

export const _getAccessToken = (client_id, account_url) => {
  const promise = new Promise((resolve, reject) => {
    accountsSdk.init({
      client_id,
      onIdentityFetched: (error, data) => {
        if (data && data.access_token) {
          return resolve(data.access_token)
        } else {
          window.location.href = `${account_url}?response_type=token&client_id=${client_id}&redirect_uri=${window.location.href}`
        }
      },
    })
  })
  return promise
}

export const useLiveChat = async ({ client_id, account_url}, getChatHistory) => {
  const accessToken = await _getAccessToken(client_id, account_url)
  if(accessToken) {
    const widget = await createDetailsWidget()
    let tempChatId = ''
    widget.on('customer_profile', (profile) => {
      _widgetModifying(widget, 'loading')
      getChatHistory(profile.chat.chat_id, accessToken)
      const setWidgetContent = async () => {
        const profileTemp = profile.chat.chat_id
        const response = await getUserStatusRequest(
          profileTemp,
          accessToken
        )
        _widgetModifying(widget, response.data)
      }
      setWidgetContent()
      if (tempChatId !== profile.chat.chat_id) {
        tempChatId = profile.chat.chat_id
        _intervalManager(false)
        _intervalManager(true, setWidgetContent, 60000)
      }
    })
  }
  return accessToken
}
