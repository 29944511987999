import React from 'react'
import History from '../../components/History'
import Forbidden from '../../components/Forbidden'
import pckgJson from '../../../package.json'

class Main extends React.Component {
  pageReturn(isWeb, { chatHistory, accessToken }) {
    let result = <History chatHistory={chatHistory} />
    if (isWeb || !accessToken) {
      result = (
        <Forbidden version={pckgJson.version} accessToken={accessToken} />
      )
    }
    return result
  }

  render() {
    const { chatHistory, isWeb, accessToken } = this.props
    return (
      <div className="App">
        {this.pageReturn(isWeb, { chatHistory, accessToken })}
        {/* <History chatHistory={chatHistory} />
        <p>{accessToken}</p>
        <p>{isIframe ? 'web page' : 'on iframe'}</p>
        <p>{pckgJson.version}</p> */}
      </div>
    )
  }
}

export default Main
