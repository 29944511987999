import { createGlobalStyle, css } from 'styled-components'

export const setColor = {
  //   disable: '#787878',
  //   success: '#249f26',
  //   error: '#b92100',
  //   buttonDisable: '#c8c8c8',
  //   textWhite: '#ffffff',
  //   warningYellow500: '#f8d200',
  //   infoBlue500: '#2952cc',
  //   text: '#000000',
  //   mainBackground: '#f4f5f7',
  //   mainWhite: '#ffffff',
  //   mainBlack: '#000000',
  textGrey: 'rgb(122,130,127)',
  grey: 'rgb(221, 226, 230)',
  mainWhite: 'rgb(255, 255, 255)',
  navy: 'rgb(66, 77, 87)',
  chatBlue: 'rgb(66, 127, 255)',
  chatBotHistory: 'rgb(242, 243, 244)',
  chatBotUser: 'rgb(66, 77, 87)',
  chatGrey: 'rgb(242, 243, 244)',
}

export const sizes = {
  huge: 1300,
  large: 1200,
  desktop: 992,
  tablet: 768,
  phone: 576,
  small: 375,
}

export const rem2vw = (size, width = 1440) => `${((size * 10) / width) * 100}vw`

export const media = Object.keys(sizes).reduce((acc, label) => {
  acc[label] = (...args) => css`
    @media (max-width: ${sizes[label]}px) {
      ${css(...args)}
    }
  `
  return acc
}, {})

export const setTransition = (
  property = 'all',
  time = '0.2s',
  timing = 'ease-in-out'
) => `transition: ${property} ${time} ${timing}`

export const setGradient = (
  direction = 'to bottom',
  colors = `#1e959c,#0d5c63`
) => `
   background-image: linear-gradient(${direction}, ${colors});
`

export const setFlex = (x = 'center', y = 'center') => `
  display:flex;
  align-items:${x};
  justify-content:${y};
`

export const textStyle = `
  font-family: Roboto;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: ${setColor.text};
  font-family: 'Roboto', sans-serif;
`

const GlobalStyle = createGlobalStyle`
  ${
    '' /* @font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: normal;
  src: url('../Assets/fonts/roboto-v20-latin-regular.eot');
  src: local('Roboto'), local('Roboto-Regular'),
       url('../Assets/fonts/roboto-v20-latin-regular.eot?#iefix') format('embedded-opentype'),
       url('../Assets/fonts/roboto-v20-latin-regular.woff2') format('woff2'),
       url('../Assets/fonts/roboto-v20-latin-regular.woff') format('woff'),
       url('../Assets/fonts/roboto-v20-latin-regular.ttf') format('truetype'),
  }
  @font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  src: url('../Assets/fonts/roboto-v20-latin-500.eot');
  src: local('Roboto Medium'), local('Roboto-Medium'),
       url('../Assets/fonts77/roboto-v20-latin-500.eot?#iefix') format('embedded-opentype'),
       url('../Assets/fonts/robo77to-v20-latin-500.woff2') format('woff2'),
       url('../Assets/fonts/roboto-v20-latin-500.woff') format('woff'),
       url('../Assets/fonts/roboto-v20-latin-500.ttf') format('truetype'),
  }
  @font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: bold;
    src: local('Roboto Black'), local('Roboto-Black'),
        url('../Assets/fonts/roboto-v20-latin-900.eot?#iefix') format('embedded-opentype'),
        url('../Assets/fonts/roboto-v20-latin-900.woff2') format('woff2'),
        url('../Assets/fonts/roboto-v20-latin-900.woff') format('woff'),
        url('../Assets/fonts/roboto-v20-latin-900.ttf') format('truetype'),
  } */
  }
  html {
    font-size: 62.5%;
    box-sizing: border-box;
    --antd-wave-shadow-color: ${setColor.success}
  }
  body, body * {
    margin: 0;
    font-size:1.6rem;
    font-family: 'Roboto', sans-serif;
    box-sizing: border-box;
  }
  button{
    border:none;
    background-color:transparent;
    outline:none;
    cursor:pointer;
  }

`

export default GlobalStyle
